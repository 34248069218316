/* CollapsibleNavbar.css */

.CollapsibleNavbar {
    background-color: #282c34;
    padding: 30px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .toggle-button {
    background-color: transparent;
    border: none;
    color: white;
    font-size: 24px;
    cursor: pointer;
  position: absolute; /* Set position to absolute */
  top: 20px; /* Adjust top position as needed */
  left: 20px; /* Adjust left position as needed */
}
    
  
  .nav-list {
    list-style: none;
    padding: 0;
    display: none;
    flex-direction: column;
    text-align: center;
  }
  
  .nav-list.open {
    display: flex;
  }
  
  .nav-item {
    margin: 10px 0;
  }
  
  .nav-item a {
    text-decoration: none;
    color: white;
    font-weight: bold;
  }
  
  .nav-item a:hover {
    text-decoration: underline;
  }
  